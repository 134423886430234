.news-detail-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;

    .news-detail-show-sidebar {
        display: none;
    }

    .news-detail-wrapper {
        width: 100%;
        min-height: 60vh;
        padding: 11rem 14rem 2rem;
        font-size: 2rem;
        text-align: left;
        display: flex;
        justify-content: space-between;

        .news-detail-left {
            width: 66%;

            .page-title {
                font-size: 2.8rem;
                color: var(--background-color);
            }

            .time-author-views {
                display: flex;
                flex-wrap: wrap;

                .time,
                .author,
                .views {
                    margin-top: 1rem;
                    text-align: center;
                    font-size: 1.35rem;
                    border: 0.1rem solid #dedede;
                    background-color: #f3f4f6;
                    border-radius: 2rem;
                    padding: 0.4rem 1rem;
                    color: #666;
                }

                .time,
                .author {
                    margin-right: 1rem;
                }
            }

            .sub-title {
                margin: 2rem 0;
                font-size: 1.5rem;
            }

            .content {
                width: 100%;

                .item {
                    width: 100%;
                    margin-bottom: 2rem;

                    h4 {
                        font-size: 1.6rem;
                        line-height: 3rem;
                    }

                    p {
                        font-size: 1.6rem;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        background-size: cover;
                        background-position: center;
                        padding: 1rem 0;
                    }
                }
            }
        }

        .news-detail-right {
            width: 30%;

            .page-title {
                font-size: 2.2rem;
                color: var(--background-color);
            }

            .item {
                width: 100%;
                max-height: 14rem;
                padding: 2rem 0;
                display: flex;
                overflow: hidden;
                border-bottom: 0.1rem solid var(--border-line);

                .title-photo {
                    width: 16rem;
                    height: auto;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 1rem;
                }

                .content {
                    flex: 1;
                    margin-left: 2rem;

                    .title {
                        font-size: 1.6rem;
                        color: var(--background-color);
                        line-height: 2.4rem;
                        max-height: 7.2rem;
                        overflow: hidden;
                        cursor: pointer;
                    }

                    .time-views {
                        display: flex;
                        flex-wrap: wrap;

                        .time,
                        .views {
                            margin-top: 0.8rem;
                            text-align: center;
                            font-size: 1.25rem;
                            border: 0.1rem solid #dedede;
                            background-color: #f3f4f6;
                            border-radius: 1rem;
                            padding: 0 1rem;
                            color: #666;
                        }

                        .time {
                            margin-right: 0.4rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1281px) {
    .news-detail-container {
        .news-detail-wrapper {
            padding: 11rem 10rem 2rem !important;
            flex-direction: column !important;

            .news-detail-left {
                width: 100% !important;
                margin-bottom: 3rem !important;
            }

            .news-detail-right {
                width: 100% !important;
                margin-bottom: 2rem !important;
            }
        }
    }
}

@media screen and (max-width: 1025px) {
    .news-detail-container {
        .news-detail-wrapper {
            padding: 11rem 4rem 2rem !important;
        }
    }
}

@media screen and (max-width: 769px) {
    .news-detail-container {
        .news-detail-show-sidebar {
            display: flex !important;
        }
        .news-detail-wrapper {
            padding: 7rem 2rem 2rem !important;
        }
    }
}

@media screen and (max-width: 500px) {
    .news-detail-container {
        .news-detail-wrapper {
            padding: 7rem 1rem 1rem !important;
            .news-detail-left {
                margin-bottom: 2rem !important;
                .page-title {
                    font-size: 2.4rem !important;
                }

                .sub-title {
                    margin: 2rem 0 1rem !important;
                }

                .content {
                    h4,
                    p {
                        font-size: 1.4rem !important;
                    }
                }
            }

            .news-detail-right {
                .item {
                    .title-photo {
                        max-width: 12rem !important;
                    }

                    .content {
                        margin-left: 1rem !important;
                        .title {
                            font-size: 1.5rem !important;
                            line-height: 2.5rem !important;
                            max-height: 5rem !important;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}
