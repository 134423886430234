.listreport-container {
    position: relative;
    text-align: left;
    padding-left: 26rem;
    width: 100%;
    height: 100%;

    .listreport-wrapper {
        .listreport-header {
            width: 100%;
            height: 8rem;
            background: var(--background-color);
            font-size: 2.8rem;
            font-weight: 600;
            color: var(--text-white-color);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .header {
            width: 100%;
            font-size: 1.5rem;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .top-product {
                font-size: 1.7rem;
                font-weight: 600;
            }

            .top-product-name {
                font-size: 1.5rem;
            }

            .top-product,
            .top-product-name {
                padding: 1rem;
                width: 100%;
                border: 0.1rem solid var(--border-line);
            }

            .top-product-number {
                font-size: 1.5rem;
                padding: 1rem 1rem 1rem 0;
            }

            .list-report {
                width: 100%;
                padding: 1rem 0;
                display: flex;
                justify-content: space-between;
                color: white;

                .item {
                    width: 24%;
                    font-size: 1.7rem;
                    border-radius: 0.5rem;

                    .top {
                        padding: 2rem 1rem;
                        display: flex;

                        .left {
                            flex: 1;
                            .count {
                                font-size: 1.8rem;
                                line-height: 3rem;
                            }
                            .title {
                                font-size: 1.5rem;
                                line-height: 3rem;
                            }
                        }

                        .right {
                            svg {
                                color: rgba(85, 85, 85, 0.3);
                                font-size: 6rem;
                            }
                        }
                    }

                    .more-info {
                        text-align: center;
                        padding: 0.4rem 1rem;
                        font-size: 1.6rem;
                        border-bottom-left-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;

                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                }

                .item:nth-child(1) {
                    background-color: #79b9eb;

                    .more-info {
                        background-color: #479fe4;
                    }
                }
                .item:nth-child(2) {
                    background-color: #36b136;
                    .more-info {
                        background-color: #3b943b;
                    }
                }
                .item:nth-child(3) {
                    background-color: #1f931f;
                    .more-info {
                        background-color: #357a35;
                    }
                }
                .item:nth-child(4) {
                    background-color: #f9b02a;
                    .more-info {
                        background-color: #cb8608;
                    }
                }
            }
        }

        .body {
            width: 100%;
            padding: 10px;

            .choice {
                width: 26rem;
                padding: 0.5rem;
                font-size: 1.6rem;

                option {
                    font-size: 1.6rem;
                }
            }

            .listreport-body {
                font-size: 1.6rem;
                padding: 10px 0;

                .listreport-body-option {
                    input {
                        width: 22rem;
                        padding: 0.5rem;
                        font-size: 1.6rem;
                    }

                    //Tắt nút tăng giảm mặc định của input
                    input[type='number']::-webkit-inner-spin-button,
                    input[type='number']::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type='number'] {
                        -moz-appearance: textfield; /* Firefox */
                    }

                    .listreport-body-option-btn {
                        margin-left: 1rem;
                        font-size: 1.6rem;
                        padding: 0.4rem 1rem;
                        cursor: pointer;
                        background-color: var(--background-color);
                        border: 0.1rem solid #666;
                        outline: none;
                        border-radius: 0.4rem;
                        color: white;
                    }
                }
            }

            .title-table {
                width: 100%;
                text-align: center;
                padding: 1rem;
                font-size: 1.8rem;
                font-weight: 600;
                border: 0.1rem solid var(--border-line);
            }

            .list {
                width: 100%;
                text-align: center;

                td {
                    min-width: 12rem;
                    img {
                        width: 6rem;
                        height: 6rem;
                        background-size: cover;
                    }
                }
            }

            table,
            th,
            tr,
            td {
                border: 0.1rem solid var(--border-line);
                border-collapse: collapse;
                padding: 1rem;
                font-size: 1.5rem;
            }
        }
    }
}
