.list-comment-container {
    padding-left: 26rem;
    width: 100%;
    height: 100%;

    .list-comment-header {
        width: 100%;
        height: 8rem;
        background: var(--background-color);
        font-size: 2.8rem;
        font-weight: 600;
        color: var(--text-white-color);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    table {
        margin: 6rem auto 0;
        width: 98%;

        tr,
        th,
        td {
            font-size: 1.5rem;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
                font-weight: 600;
                font-size: 1.4rem;
                color: var(--text-white-color);
                border: none;
                width: 8rem;
                height: 3rem;
                border-radius: 1rem;
                cursor: pointer;
            }

            .btn-edit {
                background-color: var(--background-color);
            }

            .btn-delete {
                margin-top: 1rem;
                background-color: var(--btn-color);
            }
        }
    }

    table,
    th,
    td {
        min-width: 14rem;
        border: 0.1rem solid var(--border-line);
        border-collapse: collapse;
        padding: 1rem;
    }
}

.list-comment-box {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background: var(--text-black-06);
    display: none;
    align-items: center;
    justify-content: center;

    .wrapper {
        width: 60%;
        background: var(--text-white-color);
        border-radius: 1rem;
        padding: 2rem;

        h3 {
            padding: 2rem 0 1rem;
            font-size: 2.6rem;
        }

        .wrapper-item {
            width: 100%;
            display: flex;
            align-items: center;
            margin: 1.2rem 0;

            span {
                font-size: 1.5rem;
                width: 14rem;
                text-align: left;
                padding-right: 1rem;
            }

            input {
                flex: 1;
                font-size: 1.5rem;
                padding-left: 1rem;
                padding: 1rem 0 1rem 1rem;
                border: 0.2rem solid var(--text-black-06);
                outline: none;
                border-radius: 0.3rem;
            }

            .wrapper-item-read-only {
                border: 0.1rem solid var(--border-line);
                cursor: default;
            }
        }

        button {
            font-size: 1.6rem;
            font-weight: 600;
            padding: 0.8rem 2.4rem;
            margin-top: 1rem;
            color: var(--text-white-color);
            outline: none;
            border: none;
            border-radius: 1rem;
            cursor: pointer;
        }

        .btn-edit {
            margin-right: 2rem;
            background-color: var(--text-green);
        }

        .btn-delete {
            background-color: var(--btn-color);
        }
    }
}
