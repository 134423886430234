.admin-container {
    width: 100%;
    height: 100%;

    .admin-container-navbar {
        position: fixed;
        left: 0;
        width: 26rem;
        height: 100%;
        background: var(--background-black);
        padding: 2rem;

        .admin-container-item {
            width: 100%;
            font-size: 1.6rem;
            color: var(--text-white-color);
            display: flex;
            padding: 1rem 0;
            align-items: center;

            img {
                margin-right: 1rem;
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
            }

            p {
                text-align: center;
                font-size: 1.6rem;
            }
        }

        .admin-container-item:nth-child(1) {
            border-bottom: 0.1rem solid var(--border-white-line);
        }

        .admin-container-item:nth-child(1):hover {
            opacity: 1;
            cursor: default;
        }

        .admin-container-item:hover {
            opacity: 0.7;
            cursor: pointer;
        }
    }
}
