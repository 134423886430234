*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-size: 62.5%;
    text-decoration: none;
}

:root {
    --background-color: #ff469e;
    --background: #f5f7fd;
    --background-hover: #ed75ae;
    --background-black: #423b3b;
    --background-white: #fff;
    --background-body: #f5f5f5;
    --background-grey: #f9f9f9;
    --background-input: #eaeaea;
    --background-lightorange: #e0bd79;
    --text-black-color: #333;
    --text-white-color: #fff;
    --text-black-hover: rgba(85, 85, 85, 0.8);
    --text-black-06: rgba(85, 85, 85, 0.6);
    --text-black-08: rgba(85, 85, 85, 0.8);
    --text-black-005: rgba(85, 85, 85, 0.05);
    --text-green: #7ad03a;
    --btn-color: #e60f1e;
    --boder-color: #f33a58;
    --border-line: rgba(0, 0, 0, 0.1);
    --border-white-line: #917676;
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
body,
.App {
    min-height: 100vh;
    margin: 0;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-size: 1.15rem;
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;
}
