.edituser-container {
    width: 100%;

    .edituser-wrapper {
        width: 100%;
        height: 100%;
        background: var(--background-body);
        display: flex;
        padding: 11rem 12rem 2rem;

        .edituser-right {
            width: 80%;
            border-radius: 0.1rem;
            background: var(--text-white-color);
            font-size: 1.5rem;
            padding: 2rem 4rem;

            p {
                text-align: left;
                font-size: 1.8rem;
                padding-bottom: 1.4rem;

                span {
                    font-size: 1.5rem;
                    display: block;
                    color: var(--text-black-hover);
                }
            }

            .edituser-right-info {
                padding: 3rem 2rem;
                border-top: 0.1rem solid var(--border-line);
                display: flex;

                .edituser-right-info-left {
                    width: 70%;

                    .edituser-right-info-left-item {
                        position: relative;
                        width: 100%;
                        color: var(--text-black-hover);
                        display: flex;
                        align-items: center;
                        margin: 1rem 0;

                        p {
                            font-size: 1.6rem;
                            width: 20%;
                            text-align: right;
                            padding: 0;
                        }

                        input {
                            text-align: left;
                            width: 80%;
                            display: flex;
                            font-size: 1.6rem;
                            color: var(--text-black-color);
                            margin: 0 1rem 0 3rem;
                            padding: 1rem;
                            height: 5rem;
                        }

                        svg {
                            position: absolute;
                            right: 2.6rem;
                            top: 1.8rem;
                            cursor: pointer;
                            font-size: 1.6rem;
                        }
                    }

                    .edituser-right-info-left-item:nth-child(6) {
                        .edituser-right-info-left-item-line {
                            cursor: pointer;
                            display: none;
                            position: absolute;
                            right: 2.8rem;
                            top: 2rem;
                            width: 0.2rem;
                            height: 2.4rem;
                            background-color: var(--text-black-hover);
                            transform: rotate(130deg);
                        }
                    }

                    .edituser-right-info-left-btn {
                        display: flex;
                        padding: 3rem 0 0 15rem;
                        button {
                            margin-right: 2rem;
                            font-size: 1.5rem;
                            font-weight: 600;
                            color: var(--text-white-color);
                            width: 10rem;
                            height: 4rem;
                            background-color: var(--background-color);
                            border: none;
                            border-radius: 0.2rem;
                            outline: none;
                        }

                        button:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                }

                .edituser-right-info-right {
                    width: 30%;
                    height: 24rem;
                    border-left: 0.1rem solid var(--border-line);
                    padding: 0 4rem;

                    img {
                        width: 60%;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        border-radius: 50%;
                    }

                    input#edituser-file-input {
                        display: none;
                    }

                    label {
                        margin: 1rem auto 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 10rem;
                        height: 4rem;
                        font-size: 1.5rem;
                        border: 0.1rem solid var(--boder-color);
                        border-radius: 0.3rem;
                    }

                    label:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }

                    span {
                        color: var(--text-black-hover);
                        font-size: 1.5rem;
                    }
                }

                .edituser-right-info-right-500 {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1281px) {
    .edituser-container {
        .edituser-wrapper {
            padding: 11rem 4rem 2rem !important;

            .edituser-right {
                padding: 2rem 2rem !important;

                .edituser-right-info {
                    padding: 2rem 0 !important;
                    .edituser-right-info-right {
                        padding: 0 2rem !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1025px) {
    .edituser-container {
        .edituser-wrapper {
            padding: 11rem 2rem 2rem !important;

            .edituser-right {
                padding: 2rem 2rem !important;

                .edituser-right-info {
                    .edituser-right-info-right {
                        padding: 0 1rem !important;
                    }
                }

                .edituser-right-info-left-item {
                    p {
                        width: 30% !important;
                    }
                    input {
                        margin-left: 1rem !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 901px) {
    .edituser-container {
        margin-bottom: 6.4rem !important;
        .edituser-wrapper {
            padding: 11rem 0 2rem !important;

            .edituser-right {
                width: 100% !important;
            }

            .edituser-right-info-left-btn {
                padding: 2rem 0 0 22rem !important;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .edituser-container {
        margin-bottom: 0 !important;

        .edituser-wrapper {
            padding: 7rem 0 1rem !important;

            .edituser-right {
                p {
                    font-size: 1.6rem !important;
                    font-weight: 600 !important;
                }
                span {
                    font-size: 1.4rem !important;
                    font-weight: 500 !important;
                }
            }

            .edituser-right-info-left-item {
                p,
                input {
                    font-size: 1.4rem !important;
                    font-weight: 500 !important;
                }
                input {
                    height: 4rem !important;
                }

                svg {
                    right: 2.5rem !important;
                    top: 1.5rem !important;
                    font-size: 1.3rem !important;
                }
            }

            .edituser-right-info-right {
                img {
                    width: 50% !important;
                }

                label {
                    font-size: 1.3rem !important;
                    width: 8rem !important;
                    height: 3rem !important;
                }

                span {
                    font-size: 1.3rem !important;
                }
            }
            .edituser-right-info-left-btn {
                width: 100% !important;
                align-items: center !important;
                justify-content: center !important;
                padding: 2rem 0 0 12rem !important;

                button {
                    font-size: 1.4rem !important;
                    width: 9rem !important;
                    height: 3.2rem !important;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .edituser-container {
        .edituser-wrapper {
            .edituser-right {
                width: 100% !important;
                padding: 2rem 1rem 0 !important;

                p {
                    font-size: 1.5rem !important;
                    padding-bottom: 1rem !important;
                }
                span {
                    font-size: 1.3rem !important;
                }

                .edituser-right-info {
                    padding: 1rem 0 !important;
                }
            }

            .edituser-right-info-left-item {
                p,
                input {
                    font-size: 1.3rem !important;
                }

                p {
                    padding-bottom: 0 !important;
                }
            }

            .edituser-right-info-right {
                img {
                    width: 46% !important;
                }

                label {
                    font-size: 1.2rem !important;
                    width: 7.2rem !important;
                    height: 2.4rem !important;
                }

                span {
                    font-size: 1.2rem !important;
                }
            }

            .edituser-right-info-left-btn {
                padding: 1rem 0 0 12rem !important;

                button {
                    font-size: 1.3rem !important;
                    width: 8rem !important;
                    height: 2.8rem !important;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .edituser-container {
        .edituser-wrapper {
            .edituser-right-info {
                flex-direction: column !important;

                .edituser-right-info-left {
                    width: 100% !important;

                    .edituser-right-info-left-btn {
                        padding: 0 0 0 6rem !important;
                    }

                    .edituser-right-info-left-item {
                        input {
                            margin: 0 0 0 1rem !important;
                        }

                        svg {
                            right: 1.5rem !important;
                        }
                    }
                }

                .edituser-right-info-right-1512 {
                    display: none !important;
                }

                .edituser-right-info-right-500 {
                    width: 100% !important;
                    display: flex !important;
                    height: auto !important;
                    border-left: none !important;
                    border-bottom: 0.1rem solid var(--border-line);

                    img {
                        width: 62px !important;
                        height: 62px !important;
                    }

                    .edituser-file-input-desciption {
                        flex: 1 !important;
                        padding-left: 1.4rem !important;
                        display: flex !important;
                        flex-direction: column !important;
                        margin-bottom: 1rem !important;

                        label {
                            font-size: 1.2rem !important;
                            width: 7.2rem !important;
                            height: 2.4rem !important;
                            margin: 1rem 0 1rem !important;
                        }

                        span {
                            font-size: 1.2rem !important;
                            text-align: left !important;
                        }
                    }
                }
            }
        }
    }
}
