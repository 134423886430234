.navbar-container {
    width: 100%;
    height: auto;
    box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: var(--background-white);

    .row {
        width: 86%;
        height: 10rem;
        margin: auto;
        padding: 1rem 0 0;
        display: flex;

        .logo {
            width: 30%;
            margin: auto;
            img {
                width: 34%;
                cursor: pointer;
            }
        }

        .search {
            width: 50%;
            margin: 0 auto;
            position: relative;
            padding-top: 2rem;

            input {
                width: 92%;
                height: 2.6rem;
                font-size: 1.4rem;
                color: var(--text-black-color);
                border: 0.1rem solid var(--background-color);
                outline: none;
                padding: 2rem 5rem 2rem 2rem;
                border-radius: 1rem;
            }

            .search-icon {
                position: absolute;
                right: 4rem;
                top: 3.3rem;
                border-left: 0.1rem solid var(--background-color);
                padding-left: 1rem;

                svg {
                    color: var(--background-color);
                    width: 1.8rem;
                    height: 1.8rem;
                    opacity: 0.8;
                }

                svg:hover {
                    cursor: pointer;
                    opacity: 0.6;
                }

                .search-loading {
                    animation: 1s loading ease infinite;
                    display: none;
                }

                @keyframes loading {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }

            .search-wrapper {
                position: absolute;
                left: 2.8rem;
                z-index: 1;
                width: 90%;
                min-height: 8rem;
                max-height: 41.8rem;
                overflow: hidden;
                margin: 0 auto;
                background: var(--text-white-color);
                box-shadow: 0 0.1rem 0.5rem #999;
                border-bottom-left-radius: 0.4rem;
                border-bottom-right-radius: 0.4rem;
                padding: 1rem 0;
                text-align: left;
                display: none;

                p {
                    font-size: 1.5rem;
                    color: var(--text-black-hover);
                    padding-left: 1rem;
                }

                span {
                    width: 100%;
                    display: flex;
                    font-size: 1.5rem;
                    padding: 0.8rem 0;
                    justify-content: center;
                }

                .search-wrapper-item {
                    width: 100%;
                    font-size: 1.5rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 0.8rem 2rem;
                }

                .search-wrapper-item:hover {
                    background: var(--border-line);
                    cursor: pointer;
                }
            }

            .search-wrapper:hover {
                display: block;
            }

            input:focus ~ .search-wrapper {
                //.search-wrapper là anh em liền kề với input
                display: block;
            }
        }

        .user {
            width: 30%;
            height: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;

            .menu-icon {
                position: relative;
                width: 4rem;
                height: 4rem;
                margin-left: 1rem;
                border-radius: 50%;
                color: var(--background-color);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .menu-icon-number {
                    position: absolute;
                    display: flex;
                    width: 2rem;
                    height: 2rem;
                    display: flex;
                    justify-content: center;
                    border-radius: 1rem;
                    border: 0.1rem solid #67686c;
                    top: -0.2rem;
                    right: -0.2rem;
                    background: white;
                    font-size: 1.3rem;
                }

                svg {
                    font-size: 2.5rem;
                    color: #67686c;
                }
            }

            .user-item {
                position: relative;
                height: 2rem;
                text-transform: capitalize;
                font-size: 1.5rem;
                font-weight: 600;
                padding: 0 1.2rem;
                color: #67686c;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    text-align: left;
                    height: 2rem;
                    width: 9rem;
                    font-size: 1.5rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                svg {
                    padding-right: 0.6rem;
                    font-size: 1.5rem;
                }

                img {
                    margin-right: 0.6rem;
                    border-radius: 50%;
                    width: 2.6rem;
                    height: 2.6rem;
                }

                .user-item-box {
                    display: none;
                    z-index: 6;
                    color: var(--text-black-color);
                    position: absolute;
                    top: 3rem;
                    right: 1rem;
                    width: 14rem;
                    height: 11rem;
                    border-radius: 0.2rem;
                    box-shadow: 0 0.1rem 3.125rem 0 rgb(0 0 0 / 20%);
                    background: var(--text-white-color);
                }

                .user-item-box::before {
                    position: absolute;
                    content: '';
                    top: -1.4rem;
                    left: 0;
                    width: 100%;
                    height: 1.6rem;
                }

                .user-item-box::after {
                    display: none;
                    position: absolute;
                    top: -2.4rem;
                    right: 0.6rem;
                    content: '';
                    border-width: 1.2rem 1.4rem;
                    border-style: solid;
                    border-color: transparent transparent var(--text-white-color) transparent;
                }

                .user-profile-item {
                    display: flex;
                    color: var(--text-black-color);
                    z-index: 1;
                    position: relative;
                    font-size: 1.4rem;
                    text-align: left;
                    padding: 0.8rem 0 0.8rem 1rem;
                }

                .user-profile-item:hover {
                    background-color: rgb(0 0 0 / 5%);
                }
            }

            .user-item:hover {
                cursor: pointer;
                color: var(--background-hover);
            }

            .user-profile:hover {
                color: var(--background-hover);
                .user-item-box {
                    display: block;
                }
                .user-item-box::after {
                    display: block;
                }
            }
        }
    }
}

@media screen and (max-width: 1281px) {
    .search-wrapper {
        left: 1.8rem !important;
    }
}

@media screen and (max-width: 1025px) {
    .row {
        width: 90% !important;

        .logo {
            width: 24% !important;

            img {
                width: 40% !important;
            }
        }
    }
    .user-item {
        font-size: 1.4rem !important;
    }

    .search {
        input,
        .search-wrapper {
            font-size: 1.3rem !important;

            p,
            span {
                font-size: 1.3rem !important;
            }

            .search-wrapper-item {
                font-size: 1.3rem !important;
            }
        }

        svg {
            width: 1.6rem !important;
            height: 1.6rem !important;
        }
    }
}

@media screen and (max-width: 912px) {
    .row {
        width: 94% !important;
        .logo {
            width: 16% !important;

            img {
                width: 60% !important;
            }
        }
    }

    .user {
        justify-content: space-around !important;
        .user-item {
            padding: 0 0.4rem !important;
        }
    }
}

@media screen and (max-width: 769px) {
    .navbar-container {
        .row {
            height: 6rem !important;
            width: 100% !important;
            padding-top: 0 !important;

            .logo {
                width: 10% !important;
                margin: 0 !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                img {
                    width: 80% !important;
                }
            }
            .search {
                padding-top: 0 !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                width: 78% !important;

                input {
                    width: 100% !important;
                    padding: 1.6rem 3rem 1.6rem 1rem !important;
                }

                .search-icon {
                    right: 1rem !important;
                    top: 2.2rem !important;
                }

                .search-wrapper {
                    width: 92% !important;
                    top: 4.7rem !important;
                    left: 0.8rem !important;
                }
            }

            .user {
                width: 10% !important;
                height: 100% !important;
                padding-top: 0 !important;
                .user-item {
                    display: none !important;
                }

                .menu-icon {
                    margin-left: 0 !important;
                    margin-right: 1rem !important;
                    font-size: 3.6rem !important;

                    .menu-icon-number {
                        font-size: 1.1rem !important;
                        width: 1.6rem !important;
                        height: 1.6rem !important;
                        right: 0rem !important;
                    }
                }
            }
        }
    }
}
