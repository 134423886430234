.login-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .login-logo {
        width: 100%;
        img {
            width: 100%;
        }
    }

    .login-wrapper {
        position: absolute;
        right: 12rem;
        width: 40rem;
        height: auto;
        background-color: #fff;
        border-radius: 1rem;
        padding: 1.5rem 1.5rem 2.5rem;
        font-size: 1.5rem;

        .login-title {
            font-size: 4rem;
            font-weight: 600;
            color: var(--background-color);
            line-height: 3rem;
            padding-top: 3rem;
        }

        span {
            display: block;
            padding-top: 1.6rem;
            font-size: 1.4rem;
            color: var(--boder-color);
        }

        .login-form:nth-child(1) {
            margin-top: 2.4rem;
        }

        .login-form {
            width: 100%;
            flex-wrap: wrap;
            text-align: left;
            margin: 1rem 0 0;

            label {
                width: 100%;
                font-size: 1.8rem;
                line-height: 3rem;
                display: block;
            }

            input {
                width: 100%;
                height: 4.4rem;
                font-size: 1.6rem;
                border: 0.12rem solid var(--boder-color);
                outline: none;
                border-radius: 0.3rem;
                padding: 1rem;
                transition: 0.2s ease-in;
            }

            input:focus {
                font-size: 1.5rem;
                border: 0.2rem solid var(--background-color);
            }

            span {
                padding-top: 0.2rem;
                display: flex;
                height: 0.8rem;
            }
        }

        .btn {
            position: relative;
            margin: 1.6rem 0 0.8rem;
            width: 100%;
            font-size: 1.6rem;
            font-weight: 600;
            color: var(--text-white-color);
            height: 4.4rem;
            background: var(--background-color);
            border: none;
            border-radius: 0.4rem;
            cursor: pointer;
            margin-bottom: 1.2rem;
        }

        .btn-social {
            color: #35414c !important;
            border: 2px solid #dce0e3 !important;
            background-color: white !important;
            box-shadow: none !important;

            div {
                div:nth-child(3) {
                    flex: 1 !important;
                    text-align: center !important;
                    font-size: 1.5rem !important;
                    font-weight: 600 !important;
                }
            }
        }

        .btn-facebook {
            div {
                div:nth-child(1) {
                    background-image: url('../../assets/imgs/facebook-icon.png') !important;
                    width: 2.6rem !important;
                    height: 2.6rem !important;
                    background-size: cover !important;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    svg {
                        display: none !important;
                    }
                }
            }
        }

        .login-register {
            padding-top: 1rem;
            font-size: 1.4rem;
            display: flex;
            justify-content: center;

            .login-register-link {
                display: block;
                font-size: 1.4rem;
                margin-left: 1rem;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .login-container {
        .login-wrapper {
            right: 4rem !important;
        }
    }
}

@media screen and (max-width: 1024px) {
    .login-container {
        background: linear-gradient(to bottom, #fcd9df, #cfe0f8);
        .login-wrapper {
            width: 60% !important;
            right: 0 !important;
            position: relative !important;
            height: auto !important;
        }

        .login-logo {
            display: none !important;
        }
    }
}

@media screen and (max-width: 820px) {
    .login-container {
        .login-wrapper {
            width: 70% !important;

            .login-title {
                font-size: 3.6rem;
            }

            .login-username,
            .login-password {
                label {
                    font-size: 1.7rem !important;
                }

                input {
                    font-size: 1.5rem !important;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .login-container {
        .login-wrapper {
            width: 90% !important;

            .login-title {
                font-size: 3rem;
            }

            .login-username,
            .login-password {
                label {
                    font-size: 1.5rem !important;
                }

                input {
                    font-size: 1.4rem !important;
                }
            }

            .btn {
                font-size: 1.5rem !important;
            }

            .btn-social {
                div {
                    div:nth-child(1) {
                        min-width: 2.2rem !important;
                        svg {
                            width: 2.2rem !important;
                            height: 2.2rem !important;
                        }
                    }
                    div:nth-child(3) {
                        font-size: 1.4rem !important;
                    }
                }
            }

            .btn-facebook {
                div {
                    div:nth-child(1) {
                        min-width: 2.2rem !important;
                        width: 2.2rem !important;
                        height: 2.2rem !important;
                        svg {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}
