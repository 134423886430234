.search-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;

    .search-wrapper {
        width: 100%;
        min-height: 40vh;
        padding: 2rem 20rem;

        //product
        .search-product {
            width: 100%;
            margin: 2rem 0;

            h3 {
                display: flex;
                padding: 0.4rem 1.6rem 0 0;
                font-size: 1.8rem;
            }

            .search-product-items-box {
                width: 100%;
                flex-wrap: wrap;
                display: flex;

                .search-product-items-box-item {
                    position: relative;
                    width: 20%;
                    height: 100%;
                    border: 0.1rem solid var(--border-line);
                    cursor: pointer;

                    .search-product-items-box-item-img {
                        position: absolute;
                        width: 3.8rem;
                        height: 6.8rem;
                        background-size: cover;
                        background-image: url('../../../assets/imgs/saleoff.png');

                        span {
                            font-size: 1.3rem;
                            font-weight: 600;
                            position: absolute;
                            display: flex;
                            color: var(--text-white-color);
                            bottom: 1.2rem;
                            text-align: center;
                        }
                    }

                    img {
                        width: 100%;
                        height: 14rem;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    p {
                        width: 100%;
                        height: 7.6rem;
                        font-size: 1.4rem;
                        color: var(--background-color);
                        padding: 1rem;
                        text-align: left;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    span {
                        display: block;
                        width: 100%;
                        font-size: 1.4rem;
                        background: var(--text-black-005);
                        text-align: left;
                        padding: 0 1rem;
                        line-height: 2.4rem;
                        color: var(--btn-color);
                    }

                    .search-product-items-box-item-discount {
                        color: var(--text-black-06);
                        -webkit-text-decoration-line: line-through;
                        text-decoration-line: line-through;
                    }
                }
            }
        }
    }
}
