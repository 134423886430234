.user-container {
    width: 20%;
    .user-left {
        width: 100%;
        padding: 2rem;
        display: block;

        .user-info {
            width: 100%;
            display: flex;
            align-items: center;

            img {
                border-radius: 50%;
                width: 4.8rem;
                height: 4.8rem;
                margin-right: 1.4rem;
            }

            p {
                text-align: left;
                width: 100%;

                .user-info-name {
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .user-info-edit {
                    color: var(--boder-color);
                    font-weight: 400;
                    display: block;
                    font-size: 1.4rem;

                    svg {
                        padding-right: 0.5rem;
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .user-account {
            margin: 2rem 0;
            width: 100%;
            height: auto;

            .user-account-item {
                font-size: 1.5rem;
                display: block;
                text-transform: capitalize;
                text-align: left;
                padding: 1rem 0;
                color: var(--text-black-color);

                svg {
                    font-size: 1.5rem;
                    padding-right: 1rem;
                }
            }

            .user-account-item:hover {
                cursor: pointer;
                color: var(--boder-color);
            }
        }
    }

    .user-left-mobile {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .user-container {
        .user-left {
            padding: 2rem 0 !important;
        }
    }
}

@media screen and (max-width: 901px) {
    .user-container {
        background: white !important;
        position: fixed !important;
        width: 100% !important;
        height: auto !important;
        display: flex;
        z-index: 201 !important;
        left: 0 !important;
        bottom: 0 !important;
        box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.15) !important;

        .user-left-desktop {
            display: none !important;
        }

        .user-left-mobile {
            display: flex !important;
            padding: 1rem !important;

            .user-left-mobile-item {
                flex: 1;
                svg {
                    font-size: 2.4rem;
                    color: var(--background-color);
                }

                p {
                    font-size: 1.3rem;
                    color: var(--background-color);
                    margin-top: 0.2rem;
                }
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .user-container {
        .user-left {
            .user-info {
                .user-info-name {
                    font-size: 1.4rem !important;
                }
                .user-info-edit {
                    font-size: 1.3rem !important;
                }
            }

            .user-account {
                .user-account-item {
                    font-size: 1.4rem !important;
                }
            }
        }
        .user-left-mobile {
            p {
                font-size: 1.1rem !important;
            }
        }
    }
}
