.register-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .register-logo {
        width: 100%;
        img {
            width: 100%;
        }
    }

    .register-wrapper {
        position: absolute;
        right: 12rem;
        width: 42rem;
        height: 72rem;
        background-color: #fff;
        border-radius: 1rem;
        padding: 1.5rem;
        font-size: 1.5rem;

        .register-title {
            font-size: 4rem;
            font-weight: 600;
            color: var(--background-color);
            line-height: 3rem;
            padding-top: 1.3rem;
        }

        .register-form:nth-child(1) {
            margin-top: 2rem;
        }

        .register-form {
            width: 100%;
            flex-wrap: wrap;
            text-align: left;
            margin: 1rem 0 0;

            label {
                width: 100%;
                font-size: 1.8rem;
                line-height: 3rem;
                display: block;
            }

            input {
                width: 100%;
                height: 4.4rem;
                font-size: 1.6rem;
                border: 0.12rem solid var(--boder-color);
                outline: none;
                border-radius: 0.3rem;
                padding: 1rem;
                transition: 0.2s ease-in;
            }

            input:focus {
                font-size: 1.5rem;
                border: 0.2rem solid var(--background-color);
            }

            span {
                padding-top: 0.2rem;
                display: flex;
                height: 0.8rem;
                font-size: 14px;
                color: red;
            }
        }

        .btn {
            position: relative;
            margin: 1.6rem 0 0.8rem;
            width: 100%;
            font-size: 1.6rem;
            font-weight: 600;
            color: var(--text-white-color);
            height: 4.4rem;
            background: var(--background-color);
            border: none;
            border-radius: 0.4rem;
            cursor: pointer;
        }

        .register-register {
            font-size: 1.4rem;

            .register-register-link {
                font-size: 1.4rem;
                padding-left: 0.4rem;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .register-container {
        .register-wrapper {
            right: 4rem !important;
        }
    }
}

@media screen and (max-width: 1024px) {
    .register-container {
        background: linear-gradient(to bottom, #fcd9df, #cfe0f8);
        .register-wrapper {
            width: 60% !important;
            right: 0 !important;
            position: relative !important;
            height: auto !important;
        }

        .register-logo {
            display: none !important;
        }
    }
}

@media screen and (max-width: 820px) {
    .register-container {
        .register-wrapper {
            width: 70% !important;

            .register-title {
                font-size: 3.6rem;
            }

            .register-form {
                label {
                    font-size: 1.7rem !important;
                }

                input {
                    font-size: 1.5rem !important;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .register-container {
        padding: 2rem 0;
        .register-wrapper {
            width: 90% !important;

            .register-title {
                font-size: 3rem;
            }

            .register-form {
                label {
                    font-size: 1.5rem !important;
                }

                input {
                    font-size: 1.4rem !important;
                }
            }
        }
    }
}
