.ordersNotification-container {
    width: 100%;
    height: 100%;

    .ordersNotification-wrapper {
        width: 100%;
        height: 100%;
        min-height: 60vh;
        background: var(--background-body);
        display: flex;
        padding: 2rem 12rem;

        .ordersNotification-right {
            width: 80%;
            border-radius: 0.1rem;
            background: var(--text-white-color);
            font-size: 1.5rem;
            padding: 2rem 4rem;
        }
    }
}
