.list-new-container {
    position: relative;
    padding-left: 26rem;
    width: 100%;
    height: 100%;

    .list-new-container-btn {
        position: absolute;
        right: 2rem;
        margin-top: 1rem;

        svg {
            font-size: 1.7rem;
            padding-right: 0.4rem;
        }

        button {
            padding: 1rem 1.2rem;
            background: var(--text-green);
            font-size: 1.7rem;
            font-weight: 600;
            color: var(--text-white-color);
            border: none;
            border-radius: 1rem;
        }

        button:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    .list-new-header {
        width: 100%;
        height: 8rem;
        background: var(--background-color);
        font-size: 2.8rem;
        font-weight: 600;
        color: var(--text-white-color);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    table {
        margin: 6rem auto 0;
        width: 98%;

        td {
            min-width: 10rem;
            img {
                width: 14rem;
                height: 8rem;
                background-size: cover;
            }
        }

        .list-new-description {
            overflow: hidden;
        }

        .buttons {
            min-width: 14rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
                font-weight: 600;
                font-size: 1.4rem;
                color: var(--text-white-color);
                border: none;
                width: 10rem;
                height: 4rem;
                border-radius: 1rem;
                cursor: pointer;
            }

            .btn-edit {
                background-color: var(--background-color);
            }

            .btn-delete {
                margin-top: 1rem;
                background-color: var(--btn-color);
            }
        }
    }

    table,
    th,
    tr,
    td {
        border: 0.1rem solid var(--border-line);
        border-collapse: collapse;
        padding: 1rem;
        font-size: 1.5rem;
    }
}

.list-new-box {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background: var(--text-black-06);
    display: none;
    align-items: center;
    justify-content: center;

    .wrapper {
        width: 60%;
        height: 64rem;
        background: var(--text-white-color);
        border-radius: 1rem;
        padding: 2rem;

        h3 {
            padding: 2rem;
            font-size: 2.6rem;
        }

        section {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            padding: 0 2rem;
            height: 46rem;

            .wrapper-item {
                width: 100%;
                margin: 1.2rem 0;
                display: flex;
                align-items: center;

                span {
                    font-size: 1.5rem;
                    width: 10rem;
                    text-align: left;
                    padding-right: 1rem;
                }

                input {
                    flex: 1;
                    font-size: 1.5rem;
                    padding: 1rem;
                    border: 0.2rem solid var(--text-black-06);
                    outline: none;
                    border-radius: 0.3rem;
                }

                textarea {
                    flex: 1;
                    font-size: 15px;
                    padding: 0.4rem 1rem;
                    min-height: 80px;
                    border: 0.2rem solid var(--text-black-06);
                    outline: none;
                    border-radius: 0.3rem;
                }
            }

            .wrapper-col:nth-child(1) {
                margin-right: 4rem;
            }
        }

        button {
            font-size: 1.6rem;
            font-weight: 600;
            padding: 0.8rem 2.4rem;
            margin-top: 2rem;
            color: var(--text-white-color);
            outline: none;
            border: none;
            border-radius: 1rem;
            cursor: pointer;
        }

        .btn-edit {
            margin-right: 2rem;
            background-color: var(--text-green);
        }

        .btn-delete {
            background-color: var(--btn-color);
        }
    }
}
